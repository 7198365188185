<template>
  <div>
    <b-row>
      <b-col
        md="2"
        cols="12"
        class="mb-1"
      >
        <b-form-checkbox
          v-for="option in coloptions"
          :key="option.value"
          v-model="selected"
          :value="option"
          name="applicantCols"
        >
          {{ option.text }}
        </b-form-checkbox>
      </b-col>
      <b-col
        cols="12"
        md="10"
      >
        <b-row>
          <b-col
            md="4"
            xl="4"
          >
            <b-form-group
              label-for="bTar"
              label="Başlangıç Tarihi"
            >
              <flat-pickr
                id="bTar"
                v-model="bTar"
                class="form-control"
                :config="{ altInput: true,altFormat: 'd.m.Y', dateFormat: 'Y-m-d',}"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            xl="4"
          >
            <b-form-group
              label-for="sTar"
              label="Bitiş Tarihi"
            >
              <flat-pickr
                id="sTar"
                v-model="sTar"
                class="form-control"
                :config="{ altInput: true,altFormat: 'd.m.Y', dateFormat: 'Y-m-d',}"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="2"
            xl="2"
            class="mt-2"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="d-flex align-items-center"
              variant="success"
              @click="GetActiveFirmInventory()"
            >
              <feather-icon
                icon="SearchIcon"
                class="mr-50"
              />
              <span>Bul</span>
            </b-button>
          </b-col>
          <b-col
            md="2"
            xl="2"
            class="mt-2"
          >
            <download-excel
              :data="rows"
              :fields="jsonFields"
              worksheet="Aktif Firma Listesi"
              name="AktifFirmaListesi.xls"
            >
              <b-button>Excel Olarak İndir</b-button>
            </download-excel>
          </b-col>
        </b-row>
        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{
            enabled: true,
            placeholder:'Aday Ara'}"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          theme="my-theme"
          @on-row-click="onDataGridItemClicked"
        >
          <div slot="emptystate">
            <center> Aday kaydı bulunmuyor.</center>
          </div>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">
              <span>
                <template>
                  <b-button-group
                    class="mt-25"
                    size="sm"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      size="sm"
                      squared
                      class="mr-1"
                      variant="outline-warning"
                      @click="onUpdateFirmaTipBtnClicked(props.row)"
                    >
                      <feather-icon
                        icon="Edit2Icon"
                        class="mr-50"
                      />
                      <span>Düzenle</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      size="sm"
                      squared
                      variant="outline-danger"
                      @click="onDeleteFirmaTipBtnClicked(props.row)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        class="mr-50"
                      />
                      <span>Sil</span>
                    </b-button>
                  </b-button-group>
                </template>
              </span>
            </span>
            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  1 ila
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['25','50','75','100']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> {{ props.total }} kayıt gösteriliyor </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import * as apiReports from '@/api/islemler/reports'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import {
  BFormCheckbox, BRow, BCol, BFormSelect, BPagination, BFormGroup, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
    BFormCheckbox,
    BRow,
    BCol,
    BPagination,
    BFormSelect,
    BFormGroup,
    BButton,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selected: [
        { text: 'Firma Unvanı', value: 'firmaUnvani' },
      ],
      coloptions: [
        { text: 'Firma Id', value: 'id' },
        { text: 'Firma Unvanı', value: 'firmaUnvani' },
        { text: 'Firma Türü', value: 'firmaTuru' },
        { text: 'Web Adresi', value: 'webAdresi' },
        { text: 'E-Posta', value: 'ePosta' },
        { text: 'Faaliyet Alanı', value: 'faaliyetAlani' },
        { text: 'Yetkili Kişi', value: 'yetkiliKisi' },
        { text: 'Yetkili Görevi', value: 'yetkiliGorevi' },
        { text: 'Yetkili Telefonu', value: 'yetkiliTelefon' },
        { text: 'Yetkili E-Posta', value: 'yetkiliEPosta' },
        { text: 'Müşteri Grubu Yurtiçi', value: 'yurticiMusteri' },
        { text: 'Müşteri Grubu Yurtdışı', value: 'yurtdisiMusteri' },
        { text: 'Beyaz Yakalı Personel Sayısı', value: 'beyazYaka' },
        { text: 'Mavi Yakalı Personel Sayısı', value: 'maviYaka' },
        { text: 'İl İçi Şube Sayısı', value: 'iliciSube' },
        { text: 'Toplam Şube Saysı', value: 'toplamSube' },
        { text: 'İmalat İşçi Sayısı', value: 'imalatIsciSay' },
        { text: 'Fonksiyonel Uzman Sayısı', value: 'fonksiyonelIsciSay' },
        { text: 'Mesleki Uzman Sayısı', value: 'meslekiUzmanSay' },
        { text: 'Destek Personeli Sayısı', value: 'destekPersonelSay' },
        { text: 'Firma Talep Sayısı', value: 'talepSayisi' },
        { text: 'Firma Kayıt Tarihi', value: 'kayitTarihi' },
      ],
      columns: [
        { label: 'Firma Unvanı', field: 'firmaUnvani' },
      ],
      rows: [],
      searchTerm: '',
      pageLength: 25,
      say: 0,
      bTar: '2021-01-01',
      sTar: new Date(),
      jsonFields: { 'Firma Unvanı': 'firmaUnvani' },
      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8',
          },
        ],
      ],
    }
  },
  watch: {
    selected() {
      this.columns = []
      let jsonFields = '{'
      this.selected.forEach((item, i) => {
        this.say = `${i.toString()} adet kolon`
        const column = { label: item.text, field: item.value }
        const jsonData = `"${item.text}" : "${item.value}", `
        jsonFields += jsonData
        this.columns.push(column)
      })
      jsonFields = jsonFields.substring(0, jsonFields.length - 2)
      jsonFields += '}'
      this.jsonFields = JSON.parse(jsonFields)
    },
  },
  created() {
    // this.GetActiveFirmInventory()
  },
  methods: {
    onDataGridItemClicked() {},
    GetActiveFirmInventory() {
      let filtre = null
      if (this.bTar === '' || this.sTar === '') {
        filtre = ''
        apiReports.GetActiveFirmInventory().then(resx => {
          this.rows = resx.data.list
        })
      } else {
        filtre = {
          filterObjects: [
            {
              fieldName: 'kayitTarihi',
              value: this.bTar,
              operator: '>=',
              filterJoint: 'Must',
            },
            {
              fieldName: 'kayitTarihi',
              value: this.sTar,
              operator: '<=',
              filterJoint: 'Must',
            },
          ],
        }
        apiReports.GetActiveFirmInventoryByFilter(filtre).then(res => {
          this.rows = res.data.list
        })
      }
    },
  },
}
</script>
<style lang='scss'>
   @import '@core/scss/vue/libs/vue-good-table.scss';
   @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
